import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types'

import GoogleLogo from '../../images/other/google_logo.svg'
import Avatar1 from '../../images/other/avatar1.png';
import Avatar2 from '../../images/other/avatar2.png';
import Avatar3 from '../../images/other/avatar3.png';

const Star = ({ position, rating }) => {
    const noFill = rating - position < 0;
    const fullFill = rating - position > 0;
    const partialFill = position - rating < 1;
    const fillPercentage = partialFill ? Math.round(Math.abs(1 - (position - rating)) * 100) : 0;

    return (
        <svg viewBox="0 0 24 24" width="14px" height="14px" className="mx-px" xmlns="http://www.w3.org/2000/svg" role="presentation">
            <defs>
                <linearGradient id={`gradient-${position}`}>
                    {fullFill && <stop offset="0%" stopColor="#2D7B4E" stopOpacity="1" />}
                    {partialFill && (
                        <>
                            <stop offset={`${fillPercentage}%`} stopColor="#2D7B4E" stopOpacity="1" />
                            <stop offset={`${fillPercentage}%`} stopColor="transparent" stopOpacity="0" />
                        </>
                    )}
                    {noFill && <stop offset="100%" stopColor="transparent" stopOpacity="0" />}
                </linearGradient>
            </defs>
            <path
                fill={`url('#gradient-${position}')`}
                stroke="#2D7B4E"
                strokeWidth="2px"
                d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"
            />
        </svg>
    );
};

Star.propTypes = {
    position: PropTypes.number.isRequired,
    rating: PropTypes.number.isRequired,
};

const variants = {
    STANDARD: 'standard',
    EXTENDED: 'extended',
}

const GoogleRating = ({ className, variant }) => {
    const [rating, setRating] = useState(null);

    useEffect(() => {
        async function fetchRating() {
            try {
                const response = await fetch(process.env.GATSBY_BACKEND_SERVER, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        brokeroverride: 'DIRECTACCESS',
                    },
                    body: JSON.stringify({
                        query: `
                            query placeRating($input: PlaceRatingInput!) {
                                placeRating(input: $input) {
                                    rating
                                }
                            }
                        `,
                        variables: {
                            input: {
                                placeId: 'ChIJ569DnLYcdkgR-b2SAoct1jQ'
                            },
                        },
                        operationName: 'placeRating',
                    }),
                });
                const result = await response.json();

                setRating(result.data.placeRating.rating);
            } catch (e) {
                console.error('Couldn’t fetch google rating', e);
            }
        }

        fetchRating();
    }, []);

    if (!rating) {
        return null;
    }

    return (
        <div className={`${className} flex items-center flex-wrap justify-center sm:flex-no-wrap sm:justify-start`}>
            <div className="bg-white border-t-4 border-green-500 shadow w-40 m-2">
                <a
                    href="https://search.google.com/local/reviews?placeid=ChIJ569DnLYcdkgR-b2SAoct1jQ"
                    target="_blank"
                    aria-label='GoogleRating'
                    rel="noopener noreferrer"
                >
                    <div className="flex items-center justify-between py-1 px-2">
                        <img src={GoogleLogo} alt="Google logo" width="28px" height="28px" />
                        <div>
                            <p className="text-xs mb-0">Google Rating</p>
                            <div className="flex items-center">
                                <p className="text-base mr-1 mb-0" style={{color: '#2D7B4E'}}>{rating}</p>
                                <div className="flex">
                                    {[...Array(5).keys()].map((index) => <Star position={index + 1} rating={rating} />)}
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
            {variant === variants.EXTENDED && (
                <div className="flex m-2 sm:ml-8">
                    <img src={Avatar1} className="h-10 w-10" alt="Avatar 1" />
                    <img src={Avatar2} className="h-10 w-10 z-1 -mx-4" alt="Avatar 2" />
                    <img src={Avatar3} className="h-10 w-10 z-10" alt="Avatar 3" />
                    <div className="ml-4 text-left">
                        <p className="text-base font-bold -mb-1">{`${rating}/5`}</p>
                        <p className="text-xs mb-0">from 600+ users</p>
                    </div>
                </div>
            )}
        </div>
    );
};

GoogleRating.propTypes = {
    className: PropTypes.string,
    variant: PropTypes.oneOf(Object.values(variants)),
};

GoogleRating.defaultProps = {
    className: '',
    variant: variants.STANDARD,
};

export default GoogleRating;
